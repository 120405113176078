import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const VigneronCard = ({ name, numero, mail, site, blancs, rouges, imgBouteille }) => {
    
    const imageBouteille = getImage(imgBouteille)

    return(
        <div className="vigneron-card">
            <div className="vigneron-infos">
                <h3>{name}</h3>
                <ul>
                    <li>{numero}</li>
                    <li><a href={`mailto:${mail}`}>{mail}</a></li>
                    <li><a href={site}>{site}</a></li>
                </ul>
                    <div className="cepages">
                        <p>{`${blancs.replace(/;/g,', ')}, ${rouges.replace(/;/g, ', ')}`}</p>
                    </div>
            </div>

            <div className="img-bouteille-vigneron">
                {imgBouteille && <GatsbyImage image={imageBouteille} />}
            </div>
        </div>
    )
}

export default VigneronCard;