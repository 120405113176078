import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import PageTitle from '../components/PageTitle';
import VigneronCard from '../components/VigneronCard';
import { Link } from 'gatsby';

const Vignerons = ({ data }) => {
    const vignerons = data.vignerons.edges.map((vigneron) => {
      return(
        <Link to={vigneron.node.frontmatter.slug}>
            <VigneronCard 
              name={vigneron.node.frontmatter.name}
              numero={vigneron.node.frontmatter.numero}
              mail={vigneron.node.frontmatter.mail}
              site={vigneron.node.frontmatter.site}
              blancs={vigneron.node.frontmatter.blancs}
              rouges={vigneron.node.frontmatter.rouges}
              imgBouteille={vigneron.node.frontmatter.imgBouteille} />
        </Link>
      )
    })
    
    return(
        <Layout>
        <Seo title="Vignerons" />
        <div className="main-container">
            <PageTitle title="Vignerons" />
        </div>
                
                <div className="vigneron-grid">
                    {vignerons}
                </div>
        </Layout>
    )
}

export default Vignerons;

export const pageQuery = graphql`
{
  vignerons: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/vignerons/"}}) {
    edges {
      node {
        frontmatter {
          slug
          name
          numero
          mail
          site
          blancs
          rouges
          imgBouteille {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  }
}

`;